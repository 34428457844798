import type {Company} from '$types/company';
import {ApiClient} from '@blg/jsapilib';
import type {
	MailSmtpAccountApiObject,
	CmsWebsiteContactApiObject,
	UserUserApiObject,
	SecurityUserGroupApiObject
} from '@blg/api-types';
import uniq from 'lodash/uniq';
import {get, type Writable, writable} from 'svelte/store';
import {siteConfigStore} from '$stores/siteConfig';
import {locale} from 'svelte-i18n';
import {AuthenticationException} from '@blg/api-exception';

export type LegalEntities = {ids: number[]};

export type UserGroup<T extends boolean = false> =
	SecurityUserGroupApiObject<T>;
export type User<T extends boolean = false> = UserUserApiObject<T>;
export type SmtpAccount<T extends boolean = false> =
	MailSmtpAccountApiObject<T>;
export type CmsContact<T extends boolean = false> =
	CmsWebsiteContactApiObject<T>;

export type UserInfos = {
	user: User | null;
	apiClient: ApiClient | null;
	selectedCompany?: Company | null;
	lastUpdate: number | null;
};

interface UserStore extends Writable<UserInfos> {
	setUser: (
		apiClient: ApiClient,
		user: User
	) => void;
	getCompaniesIds: () => Company['id'][];
}

export const USER_DEFAULT: UserInfos = {
	user: null,
	lastUpdate: null,
	apiClient: null,
	selectedCompany: null
};

function getLocalStorageCompany (): Company | null {
	const company = localStorage.getItem('selectedCompany');
	return company ? JSON.parse(company) : null;
}

function createUserStore (): UserStore {
	const userStore = writable(USER_DEFAULT);

	return {
		...userStore,
		setUser: (apiClient, user) => {
			const selectedCompany = getLocalStorageCompany();

			userStore.set({
				apiClient,
				user,
				lastUpdate: Date.now(),
				selectedCompany
			});
		},
		getCompaniesIds: () => {
			const user = getStoreUser();
			const companyId = user?.company?.id;

			const rightCompaniesIds =
				user?.rightCompanies.map((c) => c.company.id) ?? [];

			return companyId
				? uniq([companyId, ...rightCompaniesIds])
				: rightCompaniesIds;
		}
	};
}

/**
 * Get the authenticated user ApiClient or null if is not already set
 */
export function getApiClientOrNull (): ApiClient | null {
	return get(userStore).apiClient;
}

/**
 * Get the authenticated user ApiClient or throw if is not already set
 */
export function getAuthApiClient (): ApiClient {
	const apiClient = get(userStore).apiClient;
	if (!apiClient) {
		throw AuthenticationException.createNoAuth();
	}
	return apiClient;
}

export function getStoreUser (): User | null {
	return get(userStore).user;
}

export const userStore = createUserStore();

export const notAuthApiClientStore = writable<ApiClient>();

export function initNotAuthApiClientStore () {
	const apiUrl = get(siteConfigStore).apiUrl;
	const notAuthApiClient = new ApiClient(apiUrl, {language: get(locale) ?? 'fr-FR'});
	notAuthApiClientStore.set(notAuthApiClient);
}
