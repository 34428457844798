import {siteConfigStore} from '$stores/siteConfig';
import {getStoreUser} from '$stores/user';
import type {FormKeys, SendMailResponse} from '$types/mail';
import {datadogLogs} from '@datadog/browser-logs';
import {get} from 'svelte/store';

export function logError (error: unknown, customMessage?: string) {
	if (import.meta.env.MODE === 'production') {
		const siteConfig = get(siteConfigStore);
		datadogLogs.logger.error(
			customMessage ?? 'Error',
			{
				user: getUserData(),
				instance: siteConfig?.externalId
			},
			error as Error
		);
	} else {
		console.error(error);
	}
}

export function getUserData () {
	const user = getStoreUser();
	let userData = {};

	if (user) {
		const {id, mail, preferredLang, userGroups, username} = user;
		userData = {
			id,
			mail,
			preferredLang,
			userGroups: userGroups?.map((group) => group.group.name).join(', '),
			username
		};
	}

	return userData;
}

/**
 * Format and log simplified mail data to Datadog
 */

export function logMail (
	mailObject: SendMailResponse & {
		hardCodedTemplateName: string | undefined;
		formKey: FormKeys;
	}
) {
	const siteConfig = get(siteConfigStore);
	const data = {
		user: getUserData(),
		instance: siteConfig?.externalId,
		subject: mailObject.subject,
		mailObject: {
			smtpId: mailObject.smtpAccount?.['@id'],
			subject: mailObject.subject,
			to: mailObject.to?.map((to) => to.mail),
			cc: mailObject.cc?.map((cc) => cc.mail),
			bcc: mailObject.bcc?.map((bcc) => bcc.mail),
			replyTo: mailObject.replyTo?.map((replyTo) => replyTo.mail)
		},
		templateName: mailObject.hardCodedTemplateName,
		adminKey: mailObject.formKey
	};
	if (import.meta.env.MODE === 'production') {
		datadogLogs.logger.info('sendMail', data);
	} else {
		console.info('sendMail', data);
	}
}

export function logWarning (error: unknown, customMessage?: string) {
	if (import.meta.env.MODE === 'production') {
		const siteConfig = get(siteConfigStore);
		datadogLogs.logger.warn(
			customMessage ?? 'Warning',
			{
				user: getUserData(),
				instance: siteConfig?.externalId
			},
			error as Error
		);
	} else {
		console.warn(error);
	}
}
